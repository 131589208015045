exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-1st-post-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/news/1st-post/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-1st-post-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2nd-post-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/news/2nd-post/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2nd-post-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-3rd-post-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/news/3rd-post/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-3rd-post-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-4th-post-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/news/4th-post/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-4th-post-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-5th-post-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/news/5th-post/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-5th-post-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-6th-post-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/news/6th-post/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-6th-post-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-7th-post-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/news/7th-post/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-7th-post-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-pages-about-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-pages-about-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-pages-home-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/pages/home/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-pages-home-index-mdx" */),
  "component---src-pages-treatments-js": () => import("./../../../src/pages/treatments.js" /* webpackChunkName: "component---src-pages-treatments-js" */),
  "component---src-pages-treatments-mysql-treatment-group-id-js": () => import("./../../../src/pages/treatments/{mysqlTreatmentGroup.ID}.js" /* webpackChunkName: "component---src-pages-treatments-mysql-treatment-group-id-js" */)
}

